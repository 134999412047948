<template>
  <Layout>
    <h1 class="bd-title" id="content">Customer {{ customer.customername }}</h1>
    <a @click="this.$router.go(-1)"><font-awesome-icon icon="arrow-left" /> Back</a> 
    <form v-on:submit.prevent="submitForm">
      <div class="">
        <label for="customernumber" class="col-sm-3 uk-form-label">Customer Number</label>
        <div class="col-sm-9">
          <input type="text" class="uk-input" id="staticEmail" v-model="form.customernumber">
        </div>
      </div>
      <div class="">
        <label for="customername" class="col-sm-3 uk-form-label">Customer Name</label>
        <div class="col-sm-9">
          <input type="text" class="uk-input" id="staticEmail" v-model="form.customername">
        </div>
      </div>
      <button class="btn btn-primary btn-block" :disabled="loading">
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        <span>update</span>
      </button>


      <div class="form-group">
        <div v-if="messagefault" class="alert alert-danger" role="alert">
          {{ messagefault }}
        </div>
        <div v-if="messageok" class="alert alert-success" role="alert">
          {{ messageok }}
        </div>
      </div>

    </form>
  </Layout>
</template>

<script>
import Layout from '../layouts/private.vue'
import CustomerService from "../services/customer.service";
import EventBus from "../common/EventBus";

export default {
  name: "Customer View",
  components: { Layout },
  data() {
    return {
      id: this.$route.params.id,
      content: "Customer",
      customer: "",
      loading: false,
      messagefault: "",
      messageok: "",
      form:
      {
        customernumber: '',
        customername: '',
      }

    };
  },
  created() {
    this.customerRerender()
  },
  methods: {
    customerRerender() {
      CustomerService.getCustomer(this.$route.params.id).then(
        (response) => {
          this.customer = response.data;
          this.form.customername = this.customer.customername;
          this.form.customernumber = this.customer.customernumber;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    submitForm() {
      CustomerService.updateCustomer(this.$route.params.id, this.form).then(
        (response) => {
          this.messageok = "Saved succesfully";
          console.log(response)
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.messagefault = error.message;
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    goBack() {
        this.$router.go(-1)
      }
  },
};

</script>